@import 'primeng/resources/themes/lara-light-blue/theme.css';
@import 'primeng/resources/primeng.css';
@import 'ngx-sharebuttons/themes/default';
@import 'ngx-toastr/toastr';

body,
html {
  margin: 0;
  padding: 0;
  font-family: 'Inter', serif;
}

.ai-icon {
  width: 20px;
  height: 20px;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"> <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"/> </svg>');
  background-size: cover;
}
